import { Link } from "react-router-dom"

export default function Navbar(){

    return(
        <div className="navbar">
            <ul className="navbar__ul">
                <li className="navbar__li">
                    <Link to="/about">
                        About
                    </Link>
                </li>

                <li className="navbar__li">
                    <Link to="/artblogs">
                        Art Blogs
                    </Link>
                </li>

                <li className="navbar__li">
                    <Link to="/craftblogs">
                        Craft Blogs
                    </Link>
                </li>

                <li className="navbar__li">
                    <Link to="/travelblogs">
                        Travel Blogs
                    </Link>
                </li>
            </ul>

        </div>
        )
}
        