import Navbar from "./Navbar"
import LatestPosts from "./LatestPosts"

export default function PAGECraftBlogs(){
    return(
        <div>
            <div>
            <header>
                <div className="headerTopBar">
                    <Navbar />    
                    {/* <Socials /> */}
                </div>
                <div className="pageHeader">
                    <h1 className="pageHeader__title">Craft Blogs</h1>
                </div>
            </header>

            <main>
                <LatestPosts />
                <LatestPosts />
            </main>

            <footer>
                
            </footer>
        </div>
        </div>
    )
}