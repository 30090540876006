export default function LatestPosts(){
    return(
        <div className="latestPostsComponent">
            {/* filler content, to be update with proper js stuff later */}
            <div className="postPreviewPane">
                <div className="postPreview">
                    <img className="postPreview__img" src="https://source.unsplash.com/random/?travel/"/>
                    <div className="postPreview__text">
                        <h1 className="postPreview__title">Blog Post Title</h1>
                        <p className="postPreview__descript">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    </div>
                </div>
            </div>
            
            <div className="postPreviewPane">
                <div className="postPreview postPreviewInverse">
                    <div className="postPreview__text">
                        <h1 className="postPreview__title">Blog Post Title</h1>
                        <p className="postPreview__descript">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    </div>
                    <img className="postPreview__img" src="https://source.unsplash.com/random/?travel/"/>
                </div>
            </div>
            
            <div className="postPreviewPane">
                <div className="postPreview">
                    <img className="postPreview__img" src="https://source.unsplash.com/random/?travel/"/>
                    <div className="postPreview__text">
                        <h1 className="postPreview__title">Blog Post Title</h1>
                        <p className="postPreview__descript">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    </div>
                </div>
            </div>

        
        </div>
    )
}