import React from 'react';
import { Link } from 'react-router-dom';

export default function Socials2() {
  return (
    <div>
      <section className='socials' id='socials'>
        <div className='socials-container'>
          <ul className='social-list'>
            <Link
              className='social-list__item social-list__item--insta social-list__link bounce2'
              to='https://www.instagram.com/artventures_in_france'
              target='_blank'
            ></Link>
            <Link
              className='social-list__item social-list__item--facebook social-list__link bounce2'
              to='https://www.facebook.com/artventuresinfrance'
              target='_blank'
            ></Link>
            <Link
              className='social-list__item social-list__item--youtube social-list__link'
              to='https://www.youtube.com/@artventuresinfrance'
              target='_blank'
            ></Link>
            <Link
              className='social-list__item social-list__item--kofi social-list__link'
              to='https://ko-fi.com/artventuresinfrance'
              target='_blank'
            ></Link>
          </ul>
        </div>
      </section>
    </div>
  );
}
