//GENERIC IMPORTS
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

//COMPONENT IMPORTS
import PAGEAbout from './Components/PAGEAbout';
import PAGEArtBlogs from './Components/PAGEArtBlogs';
import PAGECraftBlogs from './Components/PAGECraftBlogs';
import PAGETravelBlogs from './Components/PAGETravelBlogs';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route exact path ="/" element={<App />} />
        <Route exact path ="/about" element={<PAGEAbout />} />
        <Route exact path ="/ArtBlogs" element={<PAGEArtBlogs />} />
        <Route exact path ="/CraftBlogs" element={<PAGECraftBlogs />} />
        <Route exact path ="/TravelBlogs" element={<PAGETravelBlogs />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
