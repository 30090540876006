import Socials from "./Socials"
import Navbar from "./Navbar"
import LatestPosts from "./LatestPosts"
import { Link } from "react-router-dom"
import Socials2 from "./Socials2"

export default function Homepage(){
    return(
        <div className="homepage">
            <img className='homepage__logo' src={require('../assets/logos/logo.webp')} alt='Blog Logo'/>
            <h1 className="homepage__comingSoon">New Website Coming Soon...</h1>
            <Socials2 />
            {/* <header>

                <div className="banner">
                    <div className="banner__logo">
                        <h3>Logo goes here</h3>
                    </div>
                    
                    <div className="banner__header">
                        <h1 className="siteTitle">Artventures in France</h1>
                    </div>
                </div>
                
                <div className="socialsBar">
                    <Socials /> 
                </div>

                <div className="navbar">
                    <Navbar />
                </div>
            </header>
            
            <main>
                <div className="aboutBanner">
                    <h1 className="aboutBanner__title">Welcome to my Blog</h1>
                    <p className="aboutBanner__text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
                        Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p>
                    <img className="aboutBanner__img"/>
                </div>

                <div className="pageDivider">
                </div>

                <div className="latestPosts">
                    <h2 className="latestPosts__title">Latest Posts</h2>
                    <LatestPosts />
                </div>
            </main>

            <footer>
                <Link to="/login" className="footer__loginButton" >Login</Link>
                <Socials />
                <p className="footer__buildCredits">This website was built by <a href="http://dnadevstudios.com" target="_blank">DNA Dev Studios</a></p>
            </footer> */}

        </div>
    )
}