import Navbar from "./Navbar"
import LatestPosts from "./LatestPosts"
import Socials from "./Socials"

export default function PAGETravelBlogs(){
    return(
        <div>
            <header>
                <div className="headerTopBar">
                    <Navbar />    
                    <Socials />
                </div>
                <div className="pageHeader">
                    <h1 className="pageHeader__title">Travel Blogs</h1>
                </div>
            </header>

            <main>
                <div className="optionsBar">
                    <div className="filters">
                        <button className="filter">Filter</button>
                        <button className="filter">Filter</button>
                        <button className="filter">Filter</button>

                        <input className="filterSearchBar" type="text" placeholder="Search..."></input>
                        <button className="search">Go!</button>
                    </div>
                </div>
                <LatestPosts />
                <LatestPosts />
            </main>

            <footer>
                
            </footer>
        </div>
    )
}